import React from "react"
import { Cover, Section, LinkButton } from "../components"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import icnEnvironment from "../images/about/icn-environment.svg"
import icnCommunity from "../images/about/icn-community.svg"
import icnSustainability from "../images/about/icn-sustainability.svg"

const About = () => {
  return (
    <Wrapper>
      <Cover>
        <div className="textContainer">
          <div className="content">
            <h1>About Us</h1>
            <p className="font-style-3">
              Our Main Focus Is Developing Greenfield Opportunities
            </p>
          </div>
        </div>
        <div className="imageContainer">
          <StaticImage src="../images/about/img-about-cover.png" />
        </div>
      </Cover>
      <Section dark className="operation">
        <div className="imageContainer">
          <StaticImage
            src="../images/about/img-about-operation-1.png"
            className="operationImg"
            alt=""
          />
          <StaticImage
            src="../images/about/img-about-operation-2.png"
            className="operationImg"
            alt=""
          />
        </div>
        <div className="textContainer">
          <p className="font-style-4">
            We have a highly skilled and experienced team working on each
            project, and we have a proven track record for successfully taking
            projects from origination through to commercial operation.
          </p>
          <p className="additionalInfo">
            We take a strategic, long-term view to renewable energy project
            development, and we are focused on developing sustainable
            large-scale renewable energy projects within South Africa – all in
            close partnership with the local community, environmental groups and
            public stakeholders.
          </p>
        </div>
      </Section>
      <Section dark className="focus">
        <div>
          <h2>Our Core Focus Areas</h2>
        </div>
        <div className="areas">
          <div>
            <img src={icnEnvironment} alt="Environment icon" />
            <h3 className="font-style-4">Environment</h3>
            <p>
              The protection of the environment is a cause close to our hearts,
              and we aim to have a positive impact in the areas that we are
              active.
            </p>
          </div>
          <div>
            <img src={icnCommunity} alt="Community icon" />
            <h3 className="font-style-4">Community</h3>
            <p>
              We involve all stakeholders to develop successful projects that
              benefit and support local communities and landowners
            </p>
          </div>
          <div>
            <img src={icnSustainability} alt="Sustainability icon" />
            <h3 className="font-style-4">Sustainability</h3>
            <p>
              We are focused on creating a sustainable future for South Africa
            </p>
          </div>
        </div>
      </Section>
      <Section className="internship">
        <div className="textContainer">
          <h2 className="font-style-4">Internship Programme</h2>
          <p>
            Red Cap would like to develop and nurture new diverse talent in the
            sector through our internship programme.
          </p>
          <p>
            If you are an engineering or environmental science student, and are
            passionate about getting into renewables, please contact us.
          </p>
          <LinkButton
            label="Make contact"
            href="mailto:info@red-cap.co.za?subject=Internship program at Red Cap"
          />
        </div>
        <div className="imageContainer">
          <StaticImage
            src="../images/about/img-about-internship.png"
            className="internshipImage"
            alt=""
          />
        </div>
      </Section>
    </Wrapper>
  )
}

export default About

const Wrapper = styled.div`
  .operation {
    display: grid;
    gap: 0 60px;
    grid-template-columns: 5fr 7fr;
    margin-top: -92px;
    padding-top: 330px;

    .imageContainer {
      display: flex;
      flex-direction: column;
      position: relative;
      transform: translateY(-105px);

      .operationImg:first-child {
        width: 350px;
      }

      .operationImg:last-child {
        transform: translate(265px, -128px);
        width: 170px;
      }
    }

    .textContainer {
      max-width: 570px;

      .additionalInfo {
        opacity: 0.8;
      }
    }
  }

  .focus {
    display: grid;
    gap: 25px 95px;
    grid-template-columns: 1fr 2fr;
    padding-bottom: 340px;

    > div:first-child {
      max-width: 320px;
    }

    .areas {
      display: grid;
      gap: 40px;
      grid-template-columns: 1fr 1fr 1fr;
      padding-top: 8px;

      img {
        height: auto;
        margin-bottom: 10px;
        width: 54px;
      }

      p {
        opacity: 0.8;
      }
    }
  }

  .internship {
    display: grid;
    gap: 0 70px;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 100px;

    .textContainer {
      align-self: center;
      display: flex;
      flex-direction: column;
      justify-self: center;
      max-width: 410px;

      h2 {
        margin: 0 0 30px 0;

        @media screen and (max-width: ${(props) =>
            props.theme.breakpoints.xs}) {
          font-size: 20px;
          line-height: 26px;
        }
      }

      p {
        margin: 0 0 20px 0;
        opacity: 0.8;

        &:last-of-type {
          margin-bottom: 60px;
        }
      }
    }

    .imageContainer {
      display: flex;
      justify-content: flex-end;

      .internshipImage {
        transform: translateY(-65px);
        width: 560px;
      }
    }
  }

  @media screen and (min-width: ${(props) =>
      props.theme.minBreakpoints.xs}) and (max-width: ${(props) =>
      props.theme.breakpoints.xl}) {
    .operation .imageContainer {
      .operationImg:first-child {
        transform: translateX(-12%);
        width: 85%;
      }

      .operationImg:last-child {
        transform: translate(80%, -128px);
        width: 70%;
      }
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    .operation {
      grid-template-columns: 1fr;
      margin-top: 0;
      padding-bottom: 60px;
      padding-top: 60px;

      .imageContainer {
        display: flex;
        flex-direction: column;
        padding: 0;
        transform: translateY(0);

        .operationImg {
          margin: 0 auto;
        }

        .operationImg:first-child {
          max-width: 350px;
        }

        .operationImg:last-child {
          margin-bottom: -68px;
          max-width: 170px;
        }
      }

      .textContainer {
        max-width: 100%;
      }
    }

    .focus {
      grid-template-columns: 1fr;
      padding-bottom: 240px;

      > div:first-child {
        max-width: 100%;
      }
    }

    .internship .imageContainer .internshipImage {
      width: 460px;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
    .focus {
      padding-bottom: 40px;
    }

    .internship {
      grid-template-columns: 1fr;
      padding-top: 60px;

      .textContainer {
        margin-bottom: 80px;
        max-width: 100%;

        p:last-of-type {
          margin-bottom: 40px;
        }
      }

      .imageContainer .internshipImage {
        margin: 0 auto;
        transform: translateY(0);
      }
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    .focus .areas {
      gap: 25px 0;
      grid-template-columns: 1fr;
    }

    .internship .imageContainer .internshipImage {
      width: 100%;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    .operation {
      .imageContainer {
        .operationImg:first-child {
          transform: translateX(-14%);
          width: 70%;
        }

        .operationImg:last-child {
          transform: translate(65%, -128px);
          width: 40%;
        }
      }

      .textContainer .font-style-4 {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }
`
